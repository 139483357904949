import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import ContentTitle from "../../../components/layout/ContentTitle";
import { useAuth0 } from "../../../components/auth/Auth0Provider";
import { CompanyControllerApiFactory, CompanyDTO } from "../../../api";
import axiosBuilder from "../../../components/auth/AxiosBuilder";
import { BASE_PATH } from '../../../api/base';
import {
    Breadcrumbs,
    Button,
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Theme,
    Typography
} from "@material-ui/core";
import { getComparator, Order, stableSort } from "../../../components/table/Sorting";
import CompanyRow from "./CompanyRow";
import Loading from "../../../components/table/Loading";
import { AxiosResponse } from "axios";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    breadcrumbs: {
        fontSize: "small"
    },
    divider: {
        margin: theme.spacing(2, 0, 3)
    }
}));

const CompanyConfiguration = () => {
    const classes = useStyles();
    const [ companies, setCompanies ] = useState<CompanyDTO[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const { getIdTokenClaims } = useAuth0();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ order, setOrder ] = useState<Order>('asc');
    const [ orderBy, setOrderBy ] = useState<keyof CompanyDTO>('title');
    const [ page, setPage ] = useState<number>(0);
    const [ rowsPerPage, setRowsPerPage ] = useState<number>(10);

    const getCompanyData = async () => {
        if (!isLoading) {
            return;
        }

        const axios = await axiosBuilder(getIdTokenClaims);

        CompanyControllerApiFactory({}, BASE_PATH, axios)
                .getAllCompaniesUsingGET()
                .then((response: AxiosResponse<Array<CompanyDTO>>) => {
                    setIsLoading(false);
                    setCompanies(response.data);
                })
                .catch((error: any) => {
                    console.log("[CompanyConfiguration] Error=" + JSON.stringify(error));
                    enqueueSnackbar('Failed fetching Company data. ' + error.toString(), { variant: 'error' });
                });
    }

    const handleSortLabelClick = (field: keyof CompanyDTO) => {
        if (orderBy === field) {
            setOrder(order === "asc" ? "desc" : "asc");

            return;
        }

        setOrderBy(field);
        setOrder("asc");
        setPage(0);
    };

    useEffect(() => {
        getCompanyData();
    });

    return (
            <div>
                <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
                    <Link color="inherit" href={"/"}>Home</Link>
                    <Typography className={classes.breadcrumbs}>Company Configuration</Typography>
                </Breadcrumbs>
                <Divider className={classes.divider}/>
                <ContentTitle title={"Company Configuration"}/>
                <p>
                    <Button variant="contained" disabled>Export CSV</Button>
                </p>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>
                                    <TableSortLabel
                                            active={orderBy === 'title'}
                                            direction={order}
                                            onClick={() => handleSortLabelClick('title')}
                                    >
                                        Title
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                            active={orderBy === 'code'}
                                            direction={order}
                                            onClick={() => handleSortLabelClick('code')}
                                    >
                                        Code
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {isLoading && <TableBody><Loading columns={3}/></TableBody>}
                        {stableSort(companies, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return <CompanyRow row={row} key={row.code}/>;
                                })}
                    </Table>
                    <TablePagination
                            rowsPerPageOptions={[ 10, 25 ]}
                            component="div"
                            count={companies.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={(event: unknown, newPage: number) => {
                                setPage(newPage);
                            }}
                            onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setRowsPerPage(parseInt(event.target.value, 10));
                                setPage(0);
                            }}
                    />
                </Paper>
            </div>
    );
}

export default CompanyConfiguration;