import React, { useEffect, useState } from 'react';
import Widget from "./Widget";
import { useSnackbar } from "notistack";
import axiosBuilder from "../auth/AxiosBuilder";
import { MordaProcessingControllerApiFactory } from "../../api";
import { BASE_PATH } from "../../api/base";
import { useAuth0 } from "../auth/Auth0Provider";

const ActiveMordaSamples = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { getIdTokenClaims } = useAuth0();
    const [ progress, setProgress ] = useState<number>(5);
    const [ mordaSampleCount, setMordaSampleCount ] = useState<number | string>("-");

    const getMordaData = async () => {
        const axios = await axiosBuilder(getIdTokenClaims);

        console.log("[ActiveMrSamples] Getting MR job count.");
        MordaProcessingControllerApiFactory({}, BASE_PATH, axios)
                .getActiveMordaJobCountUsingGET()
                .then((response) => {
                    const prefix = response.data.count === 100 ? ">" : "";
                    setMordaSampleCount(prefix + response.data.count.toString());
                })
                .catch((error: any) => {
                    setMordaSampleCount("-");
                    console.log("[ActiveMrSamples] Error=" + JSON.stringify(error));
                    enqueueSnackbar('Failed fetching Morda data. ' + error.toString(), { variant: 'error' });
                });
    }

    useEffect(() => {
        if (progress === 5) {
            getMordaData();
        }

        const interval = setInterval(() => {
            setProgress(Math.min(progress + 5, 100));
            if (progress === 100) {
                setProgress(5);

                return;
            }
        }, 3000);

        return () => clearInterval(interval);
    });

    return (
            <Widget title={"Active Morda Samples"} value={mordaSampleCount} refreshProgress={progress}/>
    );
};

export default ActiveMordaSamples;