import axios, { AxiosInstance } from 'axios';
import { BASE_PATH } from '../../api/base';
import { IdToken } from "@auth0/auth0-spa-js";

const axiosBuilder = async (getIdTokenClaims: Function): Promise<AxiosInstance> => {
    const tokenClaims: IdToken = await getIdTokenClaims();

    return axios.create({
        baseURL: BASE_PATH,
        headers: {
            'authorization': `Bearer ${tokenClaims['__raw']}`
        },
        responseType: "json"
    });
}

export default axiosBuilder;