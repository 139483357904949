import React, { useEffect, useState } from 'react';
import Widget from "./Widget";
import { useSnackbar } from "notistack";
import { useAuth0 } from "../auth/Auth0Provider";
import axiosBuilder from "../auth/AxiosBuilder";
import { XrayProcessingControllerApiFactory } from "../../api";
import { BASE_PATH } from "../../api/base";

const ActiveXraySamples = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { getIdTokenClaims } = useAuth0();
    const [ progress, setProgress ] = useState<number>(5);
    const [ xraySampleCount, setXraySampleCount ] = useState<string>("-");

    const getXrayData = async () => {
        const axios = await axiosBuilder(getIdTokenClaims);

        console.log("[ActiveXraySamples] Getting X-Ray job count.");
        XrayProcessingControllerApiFactory({}, BASE_PATH, axios)
                .getActiveXRayJobCountUsingGET()
                .then((response) => {
                    const prefix = (response.data.count === 100) ? ">" : "";
                    setXraySampleCount(
                            prefix + response.data.count.toString()
                    );
                })
                .catch((error: any) => {
                    setXraySampleCount("-");
                    console.log("[ActiveXraySamples] Error=" + JSON.stringify(error));
                    enqueueSnackbar('Failed fetching X-Ray data. ' + error.toString(), { variant: 'error' });
                });
    }

    useEffect(() => {
        if (progress === 5) {
            getXrayData();
        }

        const interval = setInterval(() => {
            setProgress(Math.min(progress + 5, 100));
            if (progress === 100) {
                setProgress(5);

                return;
            }
        }, 3000);

        return () => clearInterval(interval);
    });

    return (
            <Widget title={"Active X-Ray Samples"} value={xraySampleCount} refreshProgress={progress}/>
    );
};

export default ActiveXraySamples;