import React, { useState } from 'react';
// @ts-ignore
import { BeamlineControllerApiFactory, BeamlineDTO, SynchrotronDTO } from "../../../api";
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import axiosBuilder from "../../../components/auth/AxiosBuilder";
import { useSnackbar } from "notistack";
import { useAuth0 } from "../../../components/auth/Auth0Provider";
import Loading from "../../../components/table/Loading";
// @ts-ignore
import { BASE_PATH } from "../../../api/base";
import { AxiosResponse } from "axios";

const SynchrotronRow = (props: { row: SynchrotronDTO }) => {
    const { row } = props;
    const [ beamlines, setBeamlines ] = useState<BeamlineDTO[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const { getIdTokenClaims } = useAuth0();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ open, setOpen ] = useState<boolean>(false);

    const getBeamlineData = async () => {
        if (!isLoading) {
            return;
        }

        const axios = await axiosBuilder(getIdTokenClaims);

        BeamlineControllerApiFactory({}, BASE_PATH, axios)
                .getAllBeamlinesBySynchrotronCodeUsingGET(row.code)
                .then((response: AxiosResponse<Array<BeamlineDTO>>) => {
                    setIsLoading(false);
                    setBeamlines(response.data);
                })
                .catch((error: any) => {
                    console.log("[SynchrotronRow] Error=" + JSON.stringify(error));
                    enqueueSnackbar('Failed fetching Beamline data. ' + error.toString(), { variant: 'error' });
                });
    }
    const openDrawer = (isOpen: boolean) => {
        setOpen(isOpen);

        if (!isLoading) {
            return;
        }

        getBeamlineData();
    }

    return (
            <TableBody>
                <TableRow>
                    <TableCell>
                        <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => openDrawer(!open)}
                                title="More Information"
                        >
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.maxConcurrentDownloads}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} colSpan={4}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={3}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Code</TableCell>
                                            <TableCell>Is Synchrotron?</TableCell>
                                            <TableCell>Detector (Type / Manufacturer)</TableCell>
                                            <TableCell>Generator</TableCell>
                                            <TableCell>Diffractor</TableCell>
                                            <TableCell>Monochromator</TableCell>
                                            <TableCell>Optics</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {isLoading && <Loading columns={2}/>}
                                    {beamlines.map((beamline) => {
                                        return (
                                                <TableRow>
                                                    <TableCell>{beamline.code}</TableCell>
                                                    <TableCell>{beamline.isSynchrotron ? "Y" : "N"}</TableCell>
                                                    <TableCell>{beamline.detectorType}/{beamline.detectorManufacturer}</TableCell>
                                                    <TableCell>{beamline.xrayGeneratorModel}</TableCell>
                                                    <TableCell>{beamline.monochromaticOrLaue.toString() === "M" ? "Monochromatic" : "Laue"}</TableCell>
                                                    <TableCell>{beamline.monochromator}</TableCell>
                                                    <TableCell>{beamline.optics}</TableCell>
                                                </TableRow>
                                        );
                                    })}
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
    );
};

export default SynchrotronRow;