import React from "react";
import { AppRouteGroup } from "../components/app/AppRoutes";
import ProcessingMR from "../routes/processing/molecular-replacement/ProcessingMR";
import ProcessingXRay from "../routes/processing/xray/ProcessingXRay";
import MrIcon from "@material-ui/icons/Texture";
import XrayIcon from "@material-ui/icons/Games";

export const menuProcessingRouteData: AppRouteGroup = {
    title: "Processing",
    children: [
        {
            title: "Molecular Replacement Processing",
            path: "/processing/molecular-replacement",
            node: <ProcessingMR/>,
            icon: <MrIcon/>
        },
        {
            title: "X-Ray Processing",
            path: "/processing/x-ray",
            node: <ProcessingXRay/>,
            icon: <XrayIcon/>
        }
    ]
}