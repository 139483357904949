import React from 'react';
import Copyright from "./Copyright";
import { Typography } from "@material-ui/core";
import version from "../../resources/version.json";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    footer: {
        margin: theme.spacing(8, "auto"),
        textAlign: 'center',
    }
}));

const Footer = () => {
    const classes = useStyles();

    return (
            <footer className={classes.footer}>
                <Copyright/>
                <Typography variant="body2" color="textSecondary">v{version.version}</Typography>
            </footer>
    );
};

export default Footer;