import React, { useEffect, useState } from 'react';
import ContentTitle from "../../../components/layout/ContentTitle";
import ActiveXraySamples from "../../../components/dashboard-widgets/ActiveXraySamples";
import {
    Breadcrumbs,
    Button,
    Divider,
    Grid,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../../../components/table/Loading";
import axiosBuilder from "../../../components/auth/AxiosBuilder";
// @ts-ignore
import { XrayCollectionSummaryDTO, XrayProcessingControllerApiFactory } from "../../../api";
// @ts-ignore
import { BASE_PATH } from "../../../api/base";
import { useSnackbar } from "notistack";
import { useAuth0 } from "../../../components/auth/Auth0Provider";
import { AxiosResponse } from "axios";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
    widgets: {
        flexGrow: 1,
        marginBottom: theme.spacing(3),
    },
    breadcrumbs: {
        fontSize: "small"
    },
    divider: {
        margin: theme.spacing(2, 0, 3)
    }
}));

const ProcessingXRay = () => {
    const classes = useStyles();
    const [ collections, setCollections ] = useState<XrayCollectionSummaryDTO[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const { getIdTokenClaims } = useAuth0();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ page, setPage ] = useState<number>(0);
    const [ rowsPerPage, setRowsPerPage ] = useState<number>(10);

    const getCollectionData = async () => {
        if (!isLoading) {
            return;
        }

        const axios = await axiosBuilder(getIdTokenClaims);

        XrayProcessingControllerApiFactory({}, BASE_PATH, axios)
                .getAllCollectionsUsingGET()
                .then((response: AxiosResponse<Array<XrayCollectionSummaryDTO>>) => {
                    setIsLoading(false);
                    setCollections(response.data);
                })
                .catch((error: any) => {
                    console.log("[ProcessingXRay] Error=" + JSON.stringify(error));
                    enqueueSnackbar('Failed fetching XRay collection data. ' + error.toString(), { variant: 'error' });
                });
    }

    useEffect(() => {
        getCollectionData();
    });

    return (
            <div>
                <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
                    <Link color="inherit" href={"/"}>Home</Link>
                    <Typography className={classes.breadcrumbs}>X-Ray Processing</Typography>
                </Breadcrumbs>
                <Divider className={classes.divider}/>
                <ContentTitle title={"X-Ray Processing"}/>
                <div className={classes.widgets}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <ActiveXraySamples/>
                        </Grid>
                    </Grid>
                </div>
                <ContentTitle componentValue={"h3"} title={"Collections"}/>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>Date</TableCell>
                                <TableCell>Company Client</TableCell>
                                <TableCell>Synchrotron</TableCell>
                                <TableCell>Beamline</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading && <Loading columns={4}/>}
                            {collections.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return <TableRow
                                                key={row.uuid}
                                                style={index % 2 ? { background: "#f6f6f6" } : { background: "white" }}>
                                            <TableCell>
                                                <Button
                                                        variant="contained"
                                                        href={"/processing/x-ray/collection/" + row.uuid}>
                                                    Details
                                                </Button>
                                            </TableCell>
                                            <TableCell>{moment(row.date).format("MMM. Do, YYYY")}</TableCell>
                                            <TableCell>{row.companyClientCode}</TableCell>
                                            <TableCell>{row.synchrotronTitle} ({row.synchrotronCode})</TableCell>
                                            <TableCell>{row.beamlineCode}</TableCell>
                                        </TableRow>;
                                    })}
                        </TableBody>
                    </Table>
                    <TablePagination
                            rowsPerPageOptions={[ 10, 25 ]}
                            component="div"
                            count={collections.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={(event: unknown, newPage: number) => {
                                setPage(newPage);
                            }}
                            onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setRowsPerPage(parseInt(event.target.value, 10));
                                setPage(0);
                            }}
                    />
                </Paper>
            </div>
    );
};

export default ProcessingXRay;