import React, { useState } from 'react';
import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useAuth0 } from "../auth/Auth0Provider";
import AccountCircle from "@material-ui/icons/AccountCircle";

const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) => ({
    accountIcon: {},
    accountMenu: {
        marginTop: theme.spacing(5)
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        paddingLeft: drawerWidth,
    }
}));

const Header = () => {
    const { logout } = useAuth0();
    const classes = useStyles();
    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleLogout = () => {
        setAnchorEl(null);
        logout();
    }

    return (
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Helix Biostructures Admin Portal
                    </Typography>
                    <div className={classes.grow}/>
                    <IconButton
                            aria-controls="account-icon"
                            aria-haspopup="true"
                            color="inherit"
                            className={classes.accountIcon}
                            onClick={handleClick}
                    >
                        <AccountCircle/>
                    </IconButton>
                    <Menu
                            id="account-menu"
                            className={classes.accountMenu}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose} component="a" href="/profile">
                            Profile
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
    );
};

export default Header;