import React from 'react';
import { AppRouteGroup } from "../app/AppRoutes";
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from "@material-ui/core";

const MenuRouteList = ({ title, children }: AppRouteGroup) => (
        <List subheader={<ListSubheader>{title}</ListSubheader>}>
            {children.map((child) => (
                    <ListItem button component="a" href={child.path} key={child.title}>
                        <ListItemIcon>
                            {child.icon}
                        </ListItemIcon>
                        <ListItemText primary={child.title}/>
                    </ListItem>
            ))}
        </List>
);

export default MenuRouteList;