import React from 'react';
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, } from "@material-ui/core";
import HomeIcon from '@material-ui/icons/Home';
import { SnackbarProvider } from 'notistack';
import { menuConfigurationRouteData } from "../../data/MenuConfigurationRoutes";
import { menuProcessingRouteData } from '../../data/MenuProcessingRoutes';
import MenuRouteList from "../menu/MenuRouteList";
import Header from "./Header";
import AppContent from "../app/AppContent";
import Footer from "./Footer";

const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    }
}));

const Layout = () => {
    const classes = useStyles();

    return (
            <div className={classes.root}>
                <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Header/>
                    <Drawer
                            className={classes.drawer}
                            variant="permanent"
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                    >
                        <Toolbar/>
                        <div className={classes.drawerContainer}>
                            <List>
                                <ListItem button component="a" href="/">
                                    <ListItemIcon>
                                        <HomeIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Dashboard"/>
                                </ListItem>
                            </List>
                            <MenuRouteList
                                    title={menuProcessingRouteData.title}
                                    children={menuProcessingRouteData.children}/>
                            <MenuRouteList
                                    title={menuConfigurationRouteData.title}
                                    children={menuConfigurationRouteData.children}/>
                        </div>
                    </Drawer>
                    <main className={classes.content}>
                        <Toolbar/>
                        <AppContent/>
                        <Footer/>
                    </main>
                </SnackbarProvider>
            </div>
    );
};

export default Layout;
