import React from 'react';
import { Typography } from "@material-ui/core";

const Copyright = () => {

    return (
            <Typography variant="body2" color="textSecondary">
                Copyright &copy; {(new Date()).getFullYear()}, Helix Biostructures, LLC
            </Typography>
    );
};

export default Copyright;