import React from 'react';
import ContentTitle from "../../../components/layout/ContentTitle";
import { Breadcrumbs, Divider, Grid, Link, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ActiveMordaSamples from "../../../components/dashboard-widgets/ActiveMordaSamples";

const useStyles = makeStyles((theme: Theme) => ({
    widgets: {
        flexGrow: 1,
        marginBottom: theme.spacing(3),
    },
    breadcrumbs: {
        fontSize: "small"
    },
    divider: {
        margin: theme.spacing(2, 0, 3)
    }
}));

const ProcessingMR = () => {
    const classes = useStyles();

    return (
            <div>
                <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
                    <Link color="inherit" href={"/"}>Home</Link>
                    <Typography className={classes.breadcrumbs}>Molecular Replacement Processing</Typography>
                </Breadcrumbs>
                <Divider className={classes.divider}/>
                <ContentTitle title={"Molecular Replacement Processing"}/>
                <div className={classes.widgets}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <ActiveMordaSamples/>
                        </Grid>
                    </Grid>
                </div>
            </div>
    );
};

export default ProcessingMR;