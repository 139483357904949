import React from 'react';
import { CircularProgress, Paper, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

type WidgetType = {
    refreshProgress: number
    title: string
    value: string | number
}

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        position: 'relative',
        textAlign: 'center',
    },
    progress: {
        color: '#DDDDDD',
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(1)
    }
}));

const Widget = ({ refreshProgress, title, value }: WidgetType) => {
    const classes = useStyles();

    return (
            <Paper className={classes.paper}>
                <Typography component="h3" variant="h6" color="inherit" noWrap gutterBottom>
                    {title}
                </Typography>
                <Typography component="p" variant="h1">
                    {value}
                </Typography>
                <CircularProgress
                        className={classes.progress}
                        variant="static"
                        size="1rem"
                        value={refreshProgress}
                        thickness={10}
                        color="inherit"
                />
            </Paper>
    );
};

export default Widget;