import React from 'react';
import { Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ActiveMordaSamples from "../../components/dashboard-widgets/ActiveMordaSamples";
import ActiveXraySamples from "../../components/dashboard-widgets/ActiveXraySamples";
import ActiveDownloads from "../../components/dashboard-widgets/ActiveDownloads";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1
    }
}));

const Dashboard = () => {
    const classes = useStyles();

    return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <ActiveDownloads/>
                    </Grid>
                    <Grid item xs={3}>
                        <ActiveMordaSamples/>
                    </Grid>
                    <Grid item xs={3}>
                        <ActiveXraySamples/>
                    </Grid>
                </Grid>
            </div>
    );
};

export default Dashboard;