import React, { ElementType } from 'react';
import { Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        margin: theme.spacing(0, 0, 2)
    }
}));

type ContentTitleProps = {
    componentValue?: ElementType
    title: string
}

const ContentTitle = ({ componentValue = "h2", title }: ContentTitleProps) => {
    const classes = useStyles();

    return (
            <Typography component={componentValue} variant="h5" color="inherit" noWrap className={classes.title}>
                {title}
            </Typography>
    );
};

export default ContentTitle;