import { AppRouteGroup } from "../components/app/AppRoutes";
import React from "react";
import CollectionDetail from "../routes/processing/xray/CollectionDetail";
import SampleDetail from "../routes/processing/xray/SampleDetail";
import JobDetail from "../routes/processing/xray/JobDetail";

export const xrayProcessingRouteData: AppRouteGroup = {
    children: [
        {
            title: "Job Detail",
            path: "/processing/x-ray/collection/:collectionUuid/sample/:sampleUuid/job/:jobUuid",
            node: <JobDetail/>
        },
        {
            title: "Sample Detail",
            path: "/processing/x-ray/collection/:collectionUuid/sample/:sampleUuid",
            node: <SampleDetail/>
        },
        {
            title: "Collection Detail",
            path: "/processing/x-ray/collection/:collectionUuid",
            node: <CollectionDetail/>
        }
    ]
}