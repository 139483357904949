import React from 'react';
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../../routes/dashboard/Dashboard";

type AppRoute = {
    title: string
    path: string
    node: React.ReactNode
    icon?: React.ReactNode
}

export type AppRouteGroup = {
    title?: string,
    children: AppRoute[]
}

const AppRoutes = ({ children }: AppRouteGroup) => (
        <Switch>
            {
                children.map(appRoute => (
                        <Route path={appRoute.path} key={appRoute.title}>
                            <Helmet>
                                <title>{appRoute.title} | HLX Admin Portal</title>
                            </Helmet>
                            {appRoute.node}
                        </Route>
                ))
            }
            <Route path="/" key={"Home"}>
                <Helmet>
                    <title>Dashboard | HLX Admin Portal</title>
                </Helmet>
                <Dashboard/>
            </Route>
        </Switch>
);

export default AppRoutes;

