import React, { useState } from 'react';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// @ts-ignore
import { ClientControllerApiFactory, ClientDTO, CompanyDTO } from "../../../api";
import axiosBuilder from "../../../components/auth/AxiosBuilder";
// @ts-ignore
import { BASE_PATH } from "../../../api/base";
import { useSnackbar } from "notistack";
import { useAuth0 } from "../../../components/auth/Auth0Provider";
import Loading from "../../../components/table/Loading";
import { AxiosResponse } from "axios";

const CompanyRow = (props: { row: CompanyDTO }) => {
    const { row } = props;
    const [ clients, setClients ] = useState<ClientDTO[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const { getIdTokenClaims } = useAuth0();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ open, setOpen ] = useState<boolean>(false);

    const getClientData = async () => {
        if (!isLoading) {
            return;
        }

        const axios = await axiosBuilder(getIdTokenClaims);

        ClientControllerApiFactory({}, BASE_PATH, axios)
                .getAllClientsByCompanyCodeUsingGET(row.code)
                .then((response: AxiosResponse<Array<ClientDTO>>) => {
                    setIsLoading(false);
                    setClients(response.data);
                })
                .catch((error: any) => {
                    console.log("[CompanyRow] Error=" + JSON.stringify(error));
                    enqueueSnackbar('Failed fetching Client data. ' + error.toString(), { variant: 'error' });
                });
    }

    const openDrawer = (isOpen: boolean) => {
        setOpen(isOpen);

        if (!isLoading) {
            return;
        }

        getClientData();
    }

    return (
            <TableBody>
                <TableRow>
                    <TableCell>
                        <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => openDrawer(!open)}
                                title="More Information"
                        >
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.code}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} colSpan={4}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={3}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Client</TableCell>
                                            <TableCell>Code</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isLoading && <Loading columns={2}/>}
                                        {clients.map((client) => {
                                            return (
                                                    <TableRow>
                                                        <TableCell>{client.title}</TableCell>
                                                        <TableCell>{client.companyClientCode}</TableCell>
                                                    </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
    );
};

export default CompanyRow;