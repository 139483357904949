import React from 'react';
import AppRoutes from "./AppRoutes";
import { menuProcessingRouteData } from "../../data/MenuProcessingRoutes";
import { menuConfigurationRouteData } from "../../data/MenuConfigurationRoutes";
import { menuUserRouteData } from "../../data/MenuUserRoutes";
import { xrayProcessingRouteData } from "../../data/XrayProcessingRoutes";

const AppContent = () => {
    return (
            <AppRoutes children={
                xrayProcessingRouteData.children
                        .concat(menuProcessingRouteData.children)
                        .concat(menuConfigurationRouteData.children)
                        .concat(menuUserRouteData.children)
            }/>
    );
};

export default AppContent;