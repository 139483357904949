import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import environment from "./resources/environment.json";
import App from "./components/app/App";
import { Auth0Provider } from "./components/auth/Auth0Provider";

Sentry.init({
    dsn: "https://176a3b0b33904c0fb855b51bd696b971@o474894.ingest.sentry.io/5545930",
    environment: environment.environment,
    integrations: [
        new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

ReactDOM.render(
        <Sentry.ErrorBoundary showDialog>
            <Auth0Provider>
                <App/>
            </Auth0Provider>
        </Sentry.ErrorBoundary>,
        document.getElementById('root')
);
