// tslint:disable
/**
 * HLX Admin Portal
 * Helix Biostructures Admin Portal API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jcarter@helixbiostructures.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActionResultDTO
 */
export interface ActionResultDTO {
    /**
     * 
     * @type {string}
     * @memberof ActionResultDTO
     */
    errorMessage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionResultDTO
     */
    success: boolean;
}
/**
 * 
 * @export
 * @interface ApiMetadataDTO
 */
export interface ApiMetadataDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiMetadataDTO
     */
    version: string;
}
/**
 * 
 * @export
 * @interface BeamlineDTO
 */
export interface BeamlineDTO {
    /**
     * 
     * @type {string}
     * @memberof BeamlineDTO
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof BeamlineDTO
     */
    detectorManufacturer: string;
    /**
     * 
     * @type {string}
     * @memberof BeamlineDTO
     */
    detectorType: string;
    /**
     * 
     * @type {boolean}
     * @memberof BeamlineDTO
     */
    isSynchrotron: boolean;
    /**
     * 
     * @type {string}
     * @memberof BeamlineDTO
     */
    monochromaticOrLaue: BeamlineDTOMonochromaticOrLaueEnum;
    /**
     * 
     * @type {string}
     * @memberof BeamlineDTO
     */
    monochromator: string;
    /**
     * 
     * @type {string}
     * @memberof BeamlineDTO
     */
    optics: string;
    /**
     * 
     * @type {string}
     * @memberof BeamlineDTO
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof BeamlineDTO
     */
    xrayGeneratorModel: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BeamlineDTOMonochromaticOrLaueEnum {
    M = 'M',
    L = 'L'
}

/**
 * 
 * @export
 * @interface ClientDTO
 */
export interface ClientDTO {
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    companyClientCode: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface CompanyDTO
 */
export interface CompanyDTO {
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface DataStatisticsDTO
 */
export interface DataStatisticsDTO {
    /**
     * 
     * @type {number}
     * @memberof DataStatisticsDTO
     */
    completeness: number;
    /**
     * 
     * @type {number}
     * @memberof DataStatisticsDTO
     */
    halfSetCorrelation: number;
    /**
     * 
     * @type {number}
     * @memberof DataStatisticsDTO
     */
    i: number;
    /**
     * 
     * @type {number}
     * @memberof DataStatisticsDTO
     */
    redundancy: number;
    /**
     * 
     * @type {number}
     * @memberof DataStatisticsDTO
     */
    reflections: number;
    /**
     * 
     * @type {number}
     * @memberof DataStatisticsDTO
     */
    resolutionLower: number;
    /**
     * 
     * @type {number}
     * @memberof DataStatisticsDTO
     */
    resolutionUpper: number;
    /**
     * 
     * @type {number}
     * @memberof DataStatisticsDTO
     */
    rmeas: number;
    /**
     * 
     * @type {number}
     * @memberof DataStatisticsDTO
     */
    rmerge: number;
    /**
     * 
     * @type {number}
     * @memberof DataStatisticsDTO
     */
    rpim: number;
    /**
     * 
     * @type {number}
     * @memberof DataStatisticsDTO
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof DataStatisticsDTO
     */
    scoreVersion: string;
    /**
     * 
     * @type {number}
     * @memberof DataStatisticsDTO
     */
    uniqueReflections: number;
}
/**
 * 
 * @export
 * @interface DownloadJobCountDTO
 */
export interface DownloadJobCountDTO {
    /**
     * 
     * @type {number}
     * @memberof DownloadJobCountDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface MordaProcessingJobCountDTO
 */
export interface MordaProcessingJobCountDTO {
    /**
     * 
     * @type {number}
     * @memberof MordaProcessingJobCountDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface SynchrotronDTO
 */
export interface SynchrotronDTO {
    /**
     * 
     * @type {string}
     * @memberof SynchrotronDTO
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof SynchrotronDTO
     */
    maxConcurrentDownloads: number;
    /**
     * 
     * @type {string}
     * @memberof SynchrotronDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SynchrotronDTO
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface XrayCollectionDetailDTO
 */
export interface XrayCollectionDetailDTO {
    /**
     * 
     * @type {string}
     * @memberof XrayCollectionDetailDTO
     */
    beamlineCode: string;
    /**
     * 
     * @type {string}
     * @memberof XrayCollectionDetailDTO
     */
    companyClientCode: string;
    /**
     * 
     * @type {string}
     * @memberof XrayCollectionDetailDTO
     */
    date: string;
    /**
     * 
     * @type {Array<XraySampleSummaryDTO>}
     * @memberof XrayCollectionDetailDTO
     */
    samples: Array<XraySampleSummaryDTO>;
    /**
     * 
     * @type {string}
     * @memberof XrayCollectionDetailDTO
     */
    synchrotronCode: string;
    /**
     * 
     * @type {string}
     * @memberof XrayCollectionDetailDTO
     */
    synchrotronTitle: string;
    /**
     * 
     * @type {string}
     * @memberof XrayCollectionDetailDTO
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface XrayCollectionSummaryDTO
 */
export interface XrayCollectionSummaryDTO {
    /**
     * 
     * @type {string}
     * @memberof XrayCollectionSummaryDTO
     */
    beamlineCode: string;
    /**
     * 
     * @type {string}
     * @memberof XrayCollectionSummaryDTO
     */
    companyClientCode: string;
    /**
     * 
     * @type {string}
     * @memberof XrayCollectionSummaryDTO
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof XrayCollectionSummaryDTO
     */
    synchrotronCode: string;
    /**
     * 
     * @type {string}
     * @memberof XrayCollectionSummaryDTO
     */
    synchrotronTitle: string;
    /**
     * 
     * @type {string}
     * @memberof XrayCollectionSummaryDTO
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface XrayJobDetailDTO
 */
export interface XrayJobDetailDTO {
    /**
     * 
     * @type {string}
     * @memberof XrayJobDetailDTO
     */
    completedOn: string;
    /**
     * 
     * @type {number}
     * @memberof XrayJobDetailDTO
     */
    completeness: number;
    /**
     * 
     * @type {DataStatisticsDTO}
     * @memberof XrayJobDetailDTO
     */
    highResolutionBinDataStatistics: DataStatisticsDTO;
    /**
     * 
     * @type {DataStatisticsDTO}
     * @memberof XrayJobDetailDTO
     */
    lowResolutionBinDataStatistics: DataStatisticsDTO;
    /**
     * 
     * @type {DataStatisticsDTO}
     * @memberof XrayJobDetailDTO
     */
    overallDataStatistics: DataStatisticsDTO;
    /**
     * 
     * @type {string}
     * @memberof XrayJobDetailDTO
     */
    parameters: string;
    /**
     * 
     * @type {string}
     * @memberof XrayJobDetailDTO
     */
    promotedOn: string;
    /**
     * 
     * @type {number}
     * @memberof XrayJobDetailDTO
     */
    resolutionLower: number;
    /**
     * 
     * @type {number}
     * @memberof XrayJobDetailDTO
     */
    resolutionUpper: number;
    /**
     * 
     * @type {number}
     * @memberof XrayJobDetailDTO
     */
    rmerge: number;
    /**
     * 
     * @type {XraySampleSummaryDTO}
     * @memberof XrayJobDetailDTO
     */
    sample: XraySampleSummaryDTO;
    /**
     * 
     * @type {string}
     * @memberof XrayJobDetailDTO
     */
    spaceGroup: string;
    /**
     * 
     * @type {string}
     * @memberof XrayJobDetailDTO
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof XrayJobDetailDTO
     */
    unitCell: string;
    /**
     * 
     * @type {string}
     * @memberof XrayJobDetailDTO
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface XrayJobSummaryDTO
 */
export interface XrayJobSummaryDTO {
    /**
     * 
     * @type {string}
     * @memberof XrayJobSummaryDTO
     */
    completedOn: string;
    /**
     * 
     * @type {number}
     * @memberof XrayJobSummaryDTO
     */
    completeness: number;
    /**
     * 
     * @type {string}
     * @memberof XrayJobSummaryDTO
     */
    parameters: string;
    /**
     * 
     * @type {string}
     * @memberof XrayJobSummaryDTO
     */
    promotedOn: string;
    /**
     * 
     * @type {number}
     * @memberof XrayJobSummaryDTO
     */
    resolutionLower: number;
    /**
     * 
     * @type {number}
     * @memberof XrayJobSummaryDTO
     */
    resolutionUpper: number;
    /**
     * 
     * @type {number}
     * @memberof XrayJobSummaryDTO
     */
    rmerge: number;
    /**
     * 
     * @type {string}
     * @memberof XrayJobSummaryDTO
     */
    spaceGroup: string;
    /**
     * 
     * @type {string}
     * @memberof XrayJobSummaryDTO
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof XrayJobSummaryDTO
     */
    unitCell: string;
    /**
     * 
     * @type {string}
     * @memberof XrayJobSummaryDTO
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface XrayProcessingJobCountDTO
 */
export interface XrayProcessingJobCountDTO {
    /**
     * 
     * @type {number}
     * @memberof XrayProcessingJobCountDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface XraySampleDetailDTO
 */
export interface XraySampleDetailDTO {
    /**
     * 
     * @type {string}
     * @memberof XraySampleDetailDTO
     */
    clientCode: string;
    /**
     * 
     * @type {XrayCollectionSummaryDTO}
     * @memberof XraySampleDetailDTO
     */
    collection: XrayCollectionSummaryDTO;
    /**
     * 
     * @type {string}
     * @memberof XraySampleDetailDTO
     */
    companyCode: string;
    /**
     * 
     * @type {string}
     * @memberof XraySampleDetailDTO
     */
    compound: string;
    /**
     * 
     * @type {string}
     * @memberof XraySampleDetailDTO
     */
    datasetType: string;
    /**
     * 
     * @type {Array<XrayJobSummaryDTO>}
     * @memberof XraySampleDetailDTO
     */
    jobs: Array<XrayJobSummaryDTO>;
    /**
     * 
     * @type {string}
     * @memberof XraySampleDetailDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof XraySampleDetailDTO
     */
    protein: string;
    /**
     * 
     * @type {string}
     * @memberof XraySampleDetailDTO
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface XraySampleSummaryDTO
 */
export interface XraySampleSummaryDTO {
    /**
     * 
     * @type {string}
     * @memberof XraySampleSummaryDTO
     */
    clientCode: string;
    /**
     * 
     * @type {XrayCollectionSummaryDTO}
     * @memberof XraySampleSummaryDTO
     */
    collection: XrayCollectionSummaryDTO;
    /**
     * 
     * @type {string}
     * @memberof XraySampleSummaryDTO
     */
    companyCode: string;
    /**
     * 
     * @type {string}
     * @memberof XraySampleSummaryDTO
     */
    compound: string;
    /**
     * 
     * @type {string}
     * @memberof XraySampleSummaryDTO
     */
    datasetType: string;
    /**
     * 
     * @type {string}
     * @memberof XraySampleSummaryDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof XraySampleSummaryDTO
     */
    protein: string;
    /**
     * 
     * @type {string}
     * @memberof XraySampleSummaryDTO
     */
    uuid: string;
}

/**
 * BeamlineControllerApi - axios parameter creator
 * @export
 */
export const BeamlineControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAllBeamlinesBySynchrotronCode
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBeamlinesBySynchrotronCodeUsingGET: async (code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling getAllBeamlinesBySynchrotronCodeUsingGET.');
            }
            const localVarPath = `/api/v1/beamline/synchrotron/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BeamlineControllerApi - functional programming interface
 * @export
 */
export const BeamlineControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAllBeamlinesBySynchrotronCode
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBeamlinesBySynchrotronCodeUsingGET(code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BeamlineDTO>>> {
            const localVarAxiosArgs = await BeamlineControllerApiAxiosParamCreator(configuration).getAllBeamlinesBySynchrotronCodeUsingGET(code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BeamlineControllerApi - factory interface
 * @export
 */
export const BeamlineControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary getAllBeamlinesBySynchrotronCode
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBeamlinesBySynchrotronCodeUsingGET(code: string, options?: any): AxiosPromise<Array<BeamlineDTO>> {
            return BeamlineControllerApiFp(configuration).getAllBeamlinesBySynchrotronCodeUsingGET(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BeamlineControllerApi - object-oriented interface
 * @export
 * @class BeamlineControllerApi
 * @extends {BaseAPI}
 */
export class BeamlineControllerApi extends BaseAPI {
    /**
     * 
     * @summary getAllBeamlinesBySynchrotronCode
     * @param {string} code code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeamlineControllerApi
     */
    public getAllBeamlinesBySynchrotronCodeUsingGET(code: string, options?: any) {
        return BeamlineControllerApiFp(this.configuration).getAllBeamlinesBySynchrotronCodeUsingGET(code, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ClientControllerApi - axios parameter creator
 * @export
 */
export const ClientControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAllClientsByCompanyCode
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClientsByCompanyCodeUsingGET: async (code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling getAllClientsByCompanyCodeUsingGET.');
            }
            const localVarPath = `/api/v1/client/company/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientControllerApi - functional programming interface
 * @export
 */
export const ClientControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAllClientsByCompanyCode
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllClientsByCompanyCodeUsingGET(code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientDTO>>> {
            const localVarAxiosArgs = await ClientControllerApiAxiosParamCreator(configuration).getAllClientsByCompanyCodeUsingGET(code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientControllerApi - factory interface
 * @export
 */
export const ClientControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary getAllClientsByCompanyCode
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClientsByCompanyCodeUsingGET(code: string, options?: any): AxiosPromise<Array<ClientDTO>> {
            return ClientControllerApiFp(configuration).getAllClientsByCompanyCodeUsingGET(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientControllerApi - object-oriented interface
 * @export
 * @class ClientControllerApi
 * @extends {BaseAPI}
 */
export class ClientControllerApi extends BaseAPI {
    /**
     * 
     * @summary getAllClientsByCompanyCode
     * @param {string} code code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientControllerApi
     */
    public getAllClientsByCompanyCodeUsingGET(code: string, options?: any) {
        return ClientControllerApiFp(this.configuration).getAllClientsByCompanyCodeUsingGET(code, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CompanyControllerApi - axios parameter creator
 * @export
 */
export const CompanyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAllCompanies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompaniesUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/company`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSingleCompanyByCompanyCode
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleCompanyByCompanyCodeUsingGET: async (code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling getSingleCompanyByCompanyCodeUsingGET.');
            }
            const localVarPath = `/api/v1/company/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyControllerApi - functional programming interface
 * @export
 */
export const CompanyControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAllCompanies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompaniesUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyDTO>>> {
            const localVarAxiosArgs = await CompanyControllerApiAxiosParamCreator(configuration).getAllCompaniesUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getSingleCompanyByCompanyCode
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleCompanyByCompanyCodeUsingGET(code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDTO>> {
            const localVarAxiosArgs = await CompanyControllerApiAxiosParamCreator(configuration).getSingleCompanyByCompanyCodeUsingGET(code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CompanyControllerApi - factory interface
 * @export
 */
export const CompanyControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary getAllCompanies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompaniesUsingGET(options?: any): AxiosPromise<Array<CompanyDTO>> {
            return CompanyControllerApiFp(configuration).getAllCompaniesUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSingleCompanyByCompanyCode
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleCompanyByCompanyCodeUsingGET(code: string, options?: any): AxiosPromise<CompanyDTO> {
            return CompanyControllerApiFp(configuration).getSingleCompanyByCompanyCodeUsingGET(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyControllerApi - object-oriented interface
 * @export
 * @class CompanyControllerApi
 * @extends {BaseAPI}
 */
export class CompanyControllerApi extends BaseAPI {
    /**
     * 
     * @summary getAllCompanies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getAllCompaniesUsingGET(options?: any) {
        return CompanyControllerApiFp(this.configuration).getAllCompaniesUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSingleCompanyByCompanyCode
     * @param {string} code code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getSingleCompanyByCompanyCodeUsingGET(code: string, options?: any) {
        return CompanyControllerApiFp(this.configuration).getSingleCompanyByCompanyCodeUsingGET(code, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * DownloadControllerApi - axios parameter creator
 * @export
 */
export const DownloadControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getActiveDownloadJobCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveDownloadJobCountUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/download/job/active`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DownloadControllerApi - functional programming interface
 * @export
 */
export const DownloadControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getActiveDownloadJobCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveDownloadJobCountUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadJobCountDTO>> {
            const localVarAxiosArgs = await DownloadControllerApiAxiosParamCreator(configuration).getActiveDownloadJobCountUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DownloadControllerApi - factory interface
 * @export
 */
export const DownloadControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary getActiveDownloadJobCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveDownloadJobCountUsingGET(options?: any): AxiosPromise<DownloadJobCountDTO> {
            return DownloadControllerApiFp(configuration).getActiveDownloadJobCountUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DownloadControllerApi - object-oriented interface
 * @export
 * @class DownloadControllerApi
 * @extends {BaseAPI}
 */
export class DownloadControllerApi extends BaseAPI {
    /**
     * 
     * @summary getActiveDownloadJobCount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadControllerApi
     */
    public getActiveDownloadJobCountUsingGET(options?: any) {
        return DownloadControllerApiFp(this.configuration).getActiveDownloadJobCountUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * MetadataControllerApi - axios parameter creator
 * @export
 */
export const MetadataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getApiVersion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiVersionUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/metadata`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetadataControllerApi - functional programming interface
 * @export
 */
export const MetadataControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getApiVersion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiVersionUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiMetadataDTO>> {
            const localVarAxiosArgs = await MetadataControllerApiAxiosParamCreator(configuration).getApiVersionUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MetadataControllerApi - factory interface
 * @export
 */
export const MetadataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary getApiVersion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiVersionUsingGET(options?: any): AxiosPromise<ApiMetadataDTO> {
            return MetadataControllerApiFp(configuration).getApiVersionUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetadataControllerApi - object-oriented interface
 * @export
 * @class MetadataControllerApi
 * @extends {BaseAPI}
 */
export class MetadataControllerApi extends BaseAPI {
    /**
     * 
     * @summary getApiVersion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataControllerApi
     */
    public getApiVersionUsingGET(options?: any) {
        return MetadataControllerApiFp(this.configuration).getApiVersionUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * MordaProcessingControllerApi - axios parameter creator
 * @export
 */
export const MordaProcessingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getActiveMordaJobCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveMordaJobCountUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/morda/job/active`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MordaProcessingControllerApi - functional programming interface
 * @export
 */
export const MordaProcessingControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getActiveMordaJobCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveMordaJobCountUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MordaProcessingJobCountDTO>> {
            const localVarAxiosArgs = await MordaProcessingControllerApiAxiosParamCreator(configuration).getActiveMordaJobCountUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MordaProcessingControllerApi - factory interface
 * @export
 */
export const MordaProcessingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary getActiveMordaJobCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveMordaJobCountUsingGET(options?: any): AxiosPromise<MordaProcessingJobCountDTO> {
            return MordaProcessingControllerApiFp(configuration).getActiveMordaJobCountUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MordaProcessingControllerApi - object-oriented interface
 * @export
 * @class MordaProcessingControllerApi
 * @extends {BaseAPI}
 */
export class MordaProcessingControllerApi extends BaseAPI {
    /**
     * 
     * @summary getActiveMordaJobCount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MordaProcessingControllerApi
     */
    public getActiveMordaJobCountUsingGET(options?: any) {
        return MordaProcessingControllerApiFp(this.configuration).getActiveMordaJobCountUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * SynchrotronControllerApi - axios parameter creator
 * @export
 */
export const SynchrotronControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAllSynchrotrons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSynchrotronsUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/synchrotron`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SynchrotronControllerApi - functional programming interface
 * @export
 */
export const SynchrotronControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAllSynchrotrons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSynchrotronsUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SynchrotronDTO>>> {
            const localVarAxiosArgs = await SynchrotronControllerApiAxiosParamCreator(configuration).getAllSynchrotronsUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SynchrotronControllerApi - factory interface
 * @export
 */
export const SynchrotronControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary getAllSynchrotrons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSynchrotronsUsingGET(options?: any): AxiosPromise<Array<SynchrotronDTO>> {
            return SynchrotronControllerApiFp(configuration).getAllSynchrotronsUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SynchrotronControllerApi - object-oriented interface
 * @export
 * @class SynchrotronControllerApi
 * @extends {BaseAPI}
 */
export class SynchrotronControllerApi extends BaseAPI {
    /**
     * 
     * @summary getAllSynchrotrons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchrotronControllerApi
     */
    public getAllSynchrotronsUsingGET(options?: any) {
        return SynchrotronControllerApiFp(this.configuration).getAllSynchrotronsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * XrayProcessingControllerApi - axios parameter creator
 * @export
 */
export const XrayProcessingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getActiveXRayJobCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveXRayJobCountUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/xray/job/active`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllCollections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCollectionsUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/xray/collection`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSingleCollectionDetailByUuid
         * @param {string} uuid uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleCollectionDetailByUuidUsingGET: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling getSingleCollectionDetailByUuidUsingGET.');
            }
            const localVarPath = `/api/v1/xray/collection/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSingleJobDetailByUuid
         * @param {string} collectionUuid collectionUuid
         * @param {string} jobUuid jobUuid
         * @param {string} sampleUuid sampleUuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleJobDetailByUuidUsingGET: async (collectionUuid: string, jobUuid: string, sampleUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionUuid' is not null or undefined
            if (collectionUuid === null || collectionUuid === undefined) {
                throw new RequiredError('collectionUuid','Required parameter collectionUuid was null or undefined when calling getSingleJobDetailByUuidUsingGET.');
            }
            // verify required parameter 'jobUuid' is not null or undefined
            if (jobUuid === null || jobUuid === undefined) {
                throw new RequiredError('jobUuid','Required parameter jobUuid was null or undefined when calling getSingleJobDetailByUuidUsingGET.');
            }
            // verify required parameter 'sampleUuid' is not null or undefined
            if (sampleUuid === null || sampleUuid === undefined) {
                throw new RequiredError('sampleUuid','Required parameter sampleUuid was null or undefined when calling getSingleJobDetailByUuidUsingGET.');
            }
            const localVarPath = `/api/v1/xray/collection/{collectionUuid}/sample/{sampleUuid}/job/{jobUuid}`
                .replace(`{${"collectionUuid"}}`, encodeURIComponent(String(collectionUuid)))
                .replace(`{${"jobUuid"}}`, encodeURIComponent(String(jobUuid)))
                .replace(`{${"sampleUuid"}}`, encodeURIComponent(String(sampleUuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSingleSampleDetailByUuid
         * @param {string} collectionUuid collectionUuid
         * @param {string} sampleUuid sampleUuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleSampleDetailByUuidUsingGET: async (collectionUuid: string, sampleUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionUuid' is not null or undefined
            if (collectionUuid === null || collectionUuid === undefined) {
                throw new RequiredError('collectionUuid','Required parameter collectionUuid was null or undefined when calling getSingleSampleDetailByUuidUsingGET.');
            }
            // verify required parameter 'sampleUuid' is not null or undefined
            if (sampleUuid === null || sampleUuid === undefined) {
                throw new RequiredError('sampleUuid','Required parameter sampleUuid was null or undefined when calling getSingleSampleDetailByUuidUsingGET.');
            }
            const localVarPath = `/api/v1/xray/collection/{collectionUuid}/sample/{sampleUuid}`
                .replace(`{${"collectionUuid"}}`, encodeURIComponent(String(collectionUuid)))
                .replace(`{${"sampleUuid"}}`, encodeURIComponent(String(sampleUuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary promoteSingleJobByUuid
         * @param {string} collectionUuid collectionUuid
         * @param {string} jobUuid jobUuid
         * @param {string} sampleUuid sampleUuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoteSingleJobByUuidUsingPOST: async (collectionUuid: string, jobUuid: string, sampleUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionUuid' is not null or undefined
            if (collectionUuid === null || collectionUuid === undefined) {
                throw new RequiredError('collectionUuid','Required parameter collectionUuid was null or undefined when calling promoteSingleJobByUuidUsingPOST.');
            }
            // verify required parameter 'jobUuid' is not null or undefined
            if (jobUuid === null || jobUuid === undefined) {
                throw new RequiredError('jobUuid','Required parameter jobUuid was null or undefined when calling promoteSingleJobByUuidUsingPOST.');
            }
            // verify required parameter 'sampleUuid' is not null or undefined
            if (sampleUuid === null || sampleUuid === undefined) {
                throw new RequiredError('sampleUuid','Required parameter sampleUuid was null or undefined when calling promoteSingleJobByUuidUsingPOST.');
            }
            const localVarPath = `/api/v1/xray/collection/{collectionUuid}/sample/{sampleUuid}/job/{jobUuid}/promote`
                .replace(`{${"collectionUuid"}}`, encodeURIComponent(String(collectionUuid)))
                .replace(`{${"jobUuid"}}`, encodeURIComponent(String(jobUuid)))
                .replace(`{${"sampleUuid"}}`, encodeURIComponent(String(sampleUuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XrayProcessingControllerApi - functional programming interface
 * @export
 */
export const XrayProcessingControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getActiveXRayJobCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveXRayJobCountUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XrayProcessingJobCountDTO>> {
            const localVarAxiosArgs = await XrayProcessingControllerApiAxiosParamCreator(configuration).getActiveXRayJobCountUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getAllCollections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCollectionsUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<XrayCollectionSummaryDTO>>> {
            const localVarAxiosArgs = await XrayProcessingControllerApiAxiosParamCreator(configuration).getAllCollectionsUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getSingleCollectionDetailByUuid
         * @param {string} uuid uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleCollectionDetailByUuidUsingGET(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XrayCollectionDetailDTO>> {
            const localVarAxiosArgs = await XrayProcessingControllerApiAxiosParamCreator(configuration).getSingleCollectionDetailByUuidUsingGET(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getSingleJobDetailByUuid
         * @param {string} collectionUuid collectionUuid
         * @param {string} jobUuid jobUuid
         * @param {string} sampleUuid sampleUuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleJobDetailByUuidUsingGET(collectionUuid: string, jobUuid: string, sampleUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XrayJobDetailDTO>> {
            const localVarAxiosArgs = await XrayProcessingControllerApiAxiosParamCreator(configuration).getSingleJobDetailByUuidUsingGET(collectionUuid, jobUuid, sampleUuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getSingleSampleDetailByUuid
         * @param {string} collectionUuid collectionUuid
         * @param {string} sampleUuid sampleUuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleSampleDetailByUuidUsingGET(collectionUuid: string, sampleUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XraySampleDetailDTO>> {
            const localVarAxiosArgs = await XrayProcessingControllerApiAxiosParamCreator(configuration).getSingleSampleDetailByUuidUsingGET(collectionUuid, sampleUuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary promoteSingleJobByUuid
         * @param {string} collectionUuid collectionUuid
         * @param {string} jobUuid jobUuid
         * @param {string} sampleUuid sampleUuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promoteSingleJobByUuidUsingPOST(collectionUuid: string, jobUuid: string, sampleUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResultDTO>> {
            const localVarAxiosArgs = await XrayProcessingControllerApiAxiosParamCreator(configuration).promoteSingleJobByUuidUsingPOST(collectionUuid, jobUuid, sampleUuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * XrayProcessingControllerApi - factory interface
 * @export
 */
export const XrayProcessingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary getActiveXRayJobCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveXRayJobCountUsingGET(options?: any): AxiosPromise<XrayProcessingJobCountDTO> {
            return XrayProcessingControllerApiFp(configuration).getActiveXRayJobCountUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllCollections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCollectionsUsingGET(options?: any): AxiosPromise<Array<XrayCollectionSummaryDTO>> {
            return XrayProcessingControllerApiFp(configuration).getAllCollectionsUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSingleCollectionDetailByUuid
         * @param {string} uuid uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleCollectionDetailByUuidUsingGET(uuid: string, options?: any): AxiosPromise<XrayCollectionDetailDTO> {
            return XrayProcessingControllerApiFp(configuration).getSingleCollectionDetailByUuidUsingGET(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSingleJobDetailByUuid
         * @param {string} collectionUuid collectionUuid
         * @param {string} jobUuid jobUuid
         * @param {string} sampleUuid sampleUuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleJobDetailByUuidUsingGET(collectionUuid: string, jobUuid: string, sampleUuid: string, options?: any): AxiosPromise<XrayJobDetailDTO> {
            return XrayProcessingControllerApiFp(configuration).getSingleJobDetailByUuidUsingGET(collectionUuid, jobUuid, sampleUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSingleSampleDetailByUuid
         * @param {string} collectionUuid collectionUuid
         * @param {string} sampleUuid sampleUuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleSampleDetailByUuidUsingGET(collectionUuid: string, sampleUuid: string, options?: any): AxiosPromise<XraySampleDetailDTO> {
            return XrayProcessingControllerApiFp(configuration).getSingleSampleDetailByUuidUsingGET(collectionUuid, sampleUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary promoteSingleJobByUuid
         * @param {string} collectionUuid collectionUuid
         * @param {string} jobUuid jobUuid
         * @param {string} sampleUuid sampleUuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoteSingleJobByUuidUsingPOST(collectionUuid: string, jobUuid: string, sampleUuid: string, options?: any): AxiosPromise<ActionResultDTO> {
            return XrayProcessingControllerApiFp(configuration).promoteSingleJobByUuidUsingPOST(collectionUuid, jobUuid, sampleUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XrayProcessingControllerApi - object-oriented interface
 * @export
 * @class XrayProcessingControllerApi
 * @extends {BaseAPI}
 */
export class XrayProcessingControllerApi extends BaseAPI {
    /**
     * 
     * @summary getActiveXRayJobCount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XrayProcessingControllerApi
     */
    public getActiveXRayJobCountUsingGET(options?: any) {
        return XrayProcessingControllerApiFp(this.configuration).getActiveXRayJobCountUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllCollections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XrayProcessingControllerApi
     */
    public getAllCollectionsUsingGET(options?: any) {
        return XrayProcessingControllerApiFp(this.configuration).getAllCollectionsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSingleCollectionDetailByUuid
     * @param {string} uuid uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XrayProcessingControllerApi
     */
    public getSingleCollectionDetailByUuidUsingGET(uuid: string, options?: any) {
        return XrayProcessingControllerApiFp(this.configuration).getSingleCollectionDetailByUuidUsingGET(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSingleJobDetailByUuid
     * @param {string} collectionUuid collectionUuid
     * @param {string} jobUuid jobUuid
     * @param {string} sampleUuid sampleUuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XrayProcessingControllerApi
     */
    public getSingleJobDetailByUuidUsingGET(collectionUuid: string, jobUuid: string, sampleUuid: string, options?: any) {
        return XrayProcessingControllerApiFp(this.configuration).getSingleJobDetailByUuidUsingGET(collectionUuid, jobUuid, sampleUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSingleSampleDetailByUuid
     * @param {string} collectionUuid collectionUuid
     * @param {string} sampleUuid sampleUuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XrayProcessingControllerApi
     */
    public getSingleSampleDetailByUuidUsingGET(collectionUuid: string, sampleUuid: string, options?: any) {
        return XrayProcessingControllerApiFp(this.configuration).getSingleSampleDetailByUuidUsingGET(collectionUuid, sampleUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary promoteSingleJobByUuid
     * @param {string} collectionUuid collectionUuid
     * @param {string} jobUuid jobUuid
     * @param {string} sampleUuid sampleUuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XrayProcessingControllerApi
     */
    public promoteSingleJobByUuidUsingPOST(collectionUuid: string, jobUuid: string, sampleUuid: string, options?: any) {
        return XrayProcessingControllerApiFp(this.configuration).promoteSingleJobByUuidUsingPOST(collectionUuid, jobUuid, sampleUuid, options).then((request) => request(this.axios, this.basePath));
    }

}


