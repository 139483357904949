import React, { useContext } from 'react';
import createAuth0Client from "@auth0/auth0-spa-js";
import Auth0Client, {
    Auth0ClientOptions,
    getIdTokenClaimsOptions,
    GetTokenSilentlyOptions,
    GetTokenWithPopupOptions,
    IdToken,
    LogoutOptions,
    PopupLoginOptions,
    RedirectLoginOptions,
    RedirectLoginResult
} from "@auth0/auth0-spa-js";
import config from '../../resources/auth-config.json'

// See https://github.com/tommedema/startup-boilerplate/blob/master/packages/react-app/src/lib/auth0.tsx

export interface Auth0RedirectState {
    targetUrl?: string
}

export interface Auth0User extends Omit<IdToken, '__raw'> {
}

interface Auth0Context {
    user?: Auth0User
    isAuthenticated: boolean
    isInitializing: boolean
    isPopupOpen: boolean

    loginWithPopup(o?: PopupLoginOptions): Promise<void>

    handleRedirectCallback(): Promise<RedirectLoginResult>

    getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>

    loginWithRedirect(o?: RedirectLoginOptions): Promise<void>

    getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>

    getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>

    logout(o?: LogoutOptions): void
}

export const Auth0Context: any = React.createContext<Auth0Context | null>(null);
export const useAuth0: any = () => useContext(Auth0Context);

interface IState {
    auth0Client: any,
    isLoading: boolean,
    isAuthenticated: boolean,
    user?: any;
}

export class Auth0Provider extends React.Component<{}, IState> {
    constructor(props: any) {
        super(props)
        this.state = {
            isLoading: true,
            isAuthenticated: false,
            user: null,
            auth0Client: Auth0Client,
        };
    }

    auth0Config: Auth0ClientOptions = {
        client_id: config.clientId,
        domain: config.domain,
        redirect_uri: window.location.origin
    };

    componentDidMount() {
        const initializeAuth0 = async () => {
            const auth0Client = await createAuth0Client(this.auth0Config);
            this.setState({ auth0Client });
            // check to see if they have been redirected after login
            if (window.location.search.includes('code=')) {
                return this.handleRedirectCallback(window.location.search);
            }

            const isAuthenticated = await auth0Client.isAuthenticated();
            const user = isAuthenticated ? await auth0Client.getUser() : null;
            this.setState({ isLoading: false, isAuthenticated: isAuthenticated, user: user });

            if (!isAuthenticated) {
                await this.state.auth0Client.loginWithRedirect({
                    appState: { targetUrl: window.location.pathname }
                });
            }
        }

        initializeAuth0();
    }

    handleRedirectCallback = async (url?: string) => {
        this.setState({ isLoading: true });
        await this.state.auth0Client.handleRedirectCallback(url);
        const user = await this.state.auth0Client.getUser();
        this.setState({ user, isAuthenticated: true, isLoading: false });
        window.history.replaceState({}, document.title, window.location.pathname);
    };

    render() {
        const { auth0Client, isLoading, isAuthenticated, user } = this.state;
        const { children } = this.props;
        const loginWithRedirect = (options?: RedirectLoginOptions) =>
                auth0Client.loginWithRedirect(options);
        const getTokenSilently = (options?: GetTokenSilentlyOptions) =>
                auth0Client.getTokenSilently(options);
        const getIdTokenClaims = (options?: getIdTokenClaimsOptions) =>
                auth0Client.getIdTokenClaims(options);
        const logout = (options?: LogoutOptions) =>
                auth0Client.logout(options);

        const configObject = {
            isLoading,
            isAuthenticated,
            user,
            loginWithRedirect,
            getTokenSilently,
            getIdTokenClaims,
            logout
        };

        return <Auth0Context.Provider value={configObject}>{children}</Auth0Context.Provider>;
    }
}