import React from 'react';
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../theme";
import { CssBaseline } from "@material-ui/core";
import { useAuth0 } from "../auth/Auth0Provider";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import Layout from "../layout/Layout";
import PageLoading from "../layout/PageLoading";

const App = () => {
    const { isAuthenticated } = useAuth0();

    if (!isAuthenticated) {
        return (
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <PageLoading/>
                </ThemeProvider>
        );
    }

    return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <BrowserRouter>
                    <Switch>
                        <Route path="/">
                            <Helmet>
                                <title>Dashboard | HLX Admin Portal</title>
                            </Helmet>
                            <Layout/>
                        </Route>
                    </Switch>
                </BrowserRouter>
            </ThemeProvider>
    );
};

export default App;