import React from 'react';
import { CircularProgress, TableCell, TableRow } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    progressContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

const Loading = (props: { columns: number }) => {
    const classes = useStyles();
    const { columns } = props;

    return (
            <TableRow>
                <TableCell colSpan={columns} className={classes.progressContainer} align="center">
                    <CircularProgress/>
                </TableCell>
            </TableRow>
    );
};

export default Loading;