import React from 'react';
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    progressContainer: {
        textAlign: "center",
        margin: theme.spacing(20, "auto"),
        width: "100%"
    }
}));

const PageLoading = () => {
    const classes = useStyles();

    return (
            <div className={classes.progressContainer}>
                <CircularProgress/>
            </div>
    );
};

export default PageLoading;