import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    Breadcrumbs,
    Button,
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Theme,
    Typography
} from "@material-ui/core";
import { useParams } from "react-router-dom";
// @ts-ignore
import { XrayJobSummaryDTO, XrayProcessingControllerApiFactory, XraySampleDetailDTO } from "../../../api";
import { useSnackbar } from "notistack";
import { useAuth0 } from "../../../components/auth/Auth0Provider";
import axiosBuilder from "../../../components/auth/AxiosBuilder";
// @ts-ignore
import { BASE_PATH } from "../../../api/base";
import { AxiosResponse } from "axios";
import ContentTitle from "../../../components/layout/ContentTitle";
import PageLoading from "../../../components/layout/PageLoading";
import moment from "moment";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { getComparator, Order, stableSort } from "../../../components/table/Sorting";

const useStyles = makeStyles((theme: Theme) => ({
    sampleData: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(1),
    },
    breadcrumbs: {
        fontSize: "small"
    },
    divider: {
        margin: theme.spacing(2, 0, 3)
    }
}));

const SampleDetail = () => {
    const classes = useStyles();
    const { collectionUuid, sampleUuid } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { getIdTokenClaims } = useAuth0();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ order, setOrder ] = useState<Order>('asc');
    const [ orderBy, setOrderBy ] = useState<keyof XrayJobSummaryDTO>('resolutionLower');
    const [ sampleDetail, setSampleDetail ] = useState<XraySampleDetailDTO>({
        clientCode: "",
        collection: {
            beamlineCode: "",
            companyClientCode: "",
            date: "",
            synchrotronCode: "",
            synchrotronTitle: "",
            uuid: ""
        },
        companyCode: "",
        compound: "",
        datasetType: "",
        jobs: [],
        name: "",
        protein: "",
        uuid: ""
    });

    const getSampleDetailData = async () => {
        if (!isLoading) {
            return;
        }

        const axios = await axiosBuilder(getIdTokenClaims);
        XrayProcessingControllerApiFactory({}, BASE_PATH, axios)
                .getSingleSampleDetailByUuidUsingGET(collectionUuid, sampleUuid)
                .then((response: AxiosResponse<XraySampleDetailDTO>) => {
                    setIsLoading(false);
                    setSampleDetail(response.data);
                })
                .catch((error: any) => {
                    console.log("[ProcessingXRay/SampleDetail] Error=" + JSON.stringify(error));
                    enqueueSnackbar('Failed fetching XRay sample detail data. ' + error.toString(), { variant: 'error' });
                });
    }

    useEffect(() => {
        getSampleDetailData();
    });

    const getDetailButton = (status: string, uuid: string) => {
        if (status === "CompletedSuccessfully") {
            return (
                    <Button
                            variant="contained"
                            href={"/processing/x-ray/collection/" + collectionUuid + "/sample/" + sampleUuid + "/job/" + uuid}>
                        Details
                    </Button>
            );
        }
    }

    const getRowStatusIcon = (status: string) => {
        if (status === "CompletedSuccessfully") {
            return (
                    <SuccessIcon color={"primary"}/>
            );
        }

        return (
                <ErrorIcon color={"error"}/>
        );
    }

    const handleSortLabelClick = (field: keyof XrayJobSummaryDTO) => {
        if (orderBy === field) {
            setOrder(order === "asc" ? "desc" : "asc");

            return;
        }

        setOrderBy(field);
        setOrder("asc");
    };

    if (isLoading) {
        return (
                <div>
                    <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
                        <Link color="inherit" href={"/"}>Home</Link>
                        <Link color="inherit" href={"/processing/x-ray"}>X-Ray Processing</Link>
                        <Link color="inherit" href={"/processing/x-ray/collection/" + collectionUuid}>Collection
                            Detail</Link>
                        <Typography className={classes.breadcrumbs}>Sample Detail</Typography>
                    </Breadcrumbs>
                    <Divider className={classes.divider}/>
                    <ContentTitle componentValue={"h2"} title={"Sample Detail"}/>
                    <PageLoading/>
                </div>
        );
    } else {
        return (
                <div>
                    <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
                        <Link color="inherit" href={"/"}>Home</Link>
                        <Link color="inherit" href={"/processing/x-ray"}>X-Ray Processing</Link>
                        <Link color="inherit" href={"/processing/x-ray/collection/" + collectionUuid}>Collection
                            Detail</Link>
                        <Typography className={classes.breadcrumbs}>Sample Detail</Typography>
                    </Breadcrumbs>
                    <Divider className={classes.divider}/>
                    <ContentTitle componentValue={"h2"} title={"Sample Detail"}/>
                    <Paper className={classes.sampleData}>
                        <Typography>Name: {sampleDetail.name}</Typography>
                        <Typography>Client: {sampleDetail.companyCode}-{sampleDetail.clientCode}</Typography>
                        <Typography>
                            Collection: {moment(sampleDetail.collection.date).format("YYYYMMDD")}-{sampleDetail.collection.beamlineCode.toUpperCase()}-{sampleDetail.companyCode.toUpperCase()}-{sampleDetail.clientCode.toUpperCase()}
                        </Typography>
                        <Typography>Protein: {sampleDetail.protein}</Typography>
                        <Typography>Compound: {sampleDetail.compound}</Typography>
                    </Paper>
                    <ContentTitle componentValue={"h3"} title={"Xia2 Jobs"}/>
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>
                                        <TableSortLabel
                                                active={orderBy === 'parameters'}
                                                direction={order}
                                                onClick={() => handleSortLabelClick('parameters')}
                                        >
                                            Parameters
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                                active={orderBy === 'resolutionLower'}
                                                direction={order}
                                                onClick={() => handleSortLabelClick('resolutionLower')}
                                        >
                                            Resolution
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                                active={orderBy === 'completeness'}
                                                direction={order}
                                                onClick={() => handleSortLabelClick('completeness')}
                                        >
                                            Completeness
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                                active={orderBy === 'rmerge'}
                                                direction={order}
                                                onClick={() => handleSortLabelClick('rmerge')}
                                        >
                                            R-Merge
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Space Group</TableCell>
                                    <TableCell>Unit Cell</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Completed On</TableCell>
                                    <TableCell>Promoted On</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(sampleDetail.jobs, getComparator(order, orderBy))
                                        .map((row: XrayJobSummaryDTO, index: number) => {
                                            return (
                                                    <TableRow
                                                            key={row.uuid}
                                                            style={index % 2 ? { background: "#f6f6f6" } : { background: "white" }}>
                                                        <TableCell>{getDetailButton(row.status as string, row.uuid as string)}</TableCell>
                                                        <TableCell>{row.parameters}</TableCell>
                                                        <TableCell>{row.resolutionUpper} - {row.resolutionLower}</TableCell>
                                                        <TableCell>{row.completeness}</TableCell>
                                                        <TableCell>{row.rmerge}</TableCell>
                                                        <TableCell>{row.spaceGroup}</TableCell>
                                                        <TableCell>{row.unitCell}</TableCell>
                                                        <TableCell>{getRowStatusIcon(row.status as string)}</TableCell>
                                                        <TableCell>{row.completedOn === "" ? "" : moment(row.completedOn).format("MMM Do, YYYY")} at {moment(row.completedOn).format("h:m A")}</TableCell>
                                                        <TableCell>{row.promotedOn === "" ? "" : moment(row.promotedOn).format("MMM Do, YYYY")}</TableCell>
                                                    </TableRow>
                                            );
                                        })}
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
        );
    }
};

export default SampleDetail;