import React from 'react';
import ContentTitle from "../../components/layout/ContentTitle";
import { useAuth0 } from "../../components/auth/Auth0Provider";
import { Button, Chip, Grid, MenuItem, TextField } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';

const Profile = () => {
    const { user } = useAuth0();

    let emailVerifiedChip;
    if (user['email_verified']) {
        emailVerifiedChip = <Chip icon={<CheckIcon/>} color={"primary"} label="Email Address Verified"/>;
    } else {
        emailVerifiedChip = <Button variant={"contained"} color="primary">Resend Email Verification</Button>;
    }

    return (
            <div>
                <ContentTitle title={"Profile"}/>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField label="Email Address" disabled defaultValue={user['email']} fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        {emailVerifiedChip}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField select defaultValue={"admin"} disabled label="Role">
                            <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="user">User</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </div>
    );
};

export default Profile;