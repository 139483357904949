import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAuth0 } from "../../../components/auth/Auth0Provider";
import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumbs, Divider, Link, Paper, Theme, Typography } from "@material-ui/core";
import ContentTitle from "../../../components/layout/ContentTitle";
import PageLoading from "../../../components/layout/PageLoading";
import axiosBuilder from "../../../components/auth/AxiosBuilder";
import { XrayJobDetailDTO, XrayProcessingControllerApiFactory } from "../../../api";
import { BASE_PATH } from "../../../api/base";
import { AxiosResponse } from "axios";
import moment from "moment";
import PromoteButton from "./PromoteButton";

const useStyles = makeStyles((theme: Theme) => ({
    jobData: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(1),
    },
    breadcrumbs: {
        fontSize: "small"
    },
    divider: {
        margin: theme.spacing(2, 0, 3)
    }
}));

const JobDetail = () => {
    const classes = useStyles();
    const { collectionUuid, sampleUuid, jobUuid } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { getIdTokenClaims } = useAuth0();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ jobDetail, setJobDetail ] = useState<XrayJobDetailDTO>({
        completeness: 0,
        highResolutionBinDataStatistics: {
            completeness: 0,
            halfSetCorrelation: 0,
            i: 0,
            redundancy: 0,
            reflections: 0,
            resolutionLower: 0,
            resolutionUpper: 0,
            rmerge: 0,
            rmeas: 0,
            rpim: 0,
            score: 0,
            scoreVersion: "",
            uniqueReflections: 0
        },
        lowResolutionBinDataStatistics: {
            completeness: 0,
            halfSetCorrelation: 0,
            i: 0,
            redundancy: 0,
            reflections: 0,
            resolutionLower: 0,
            resolutionUpper: 0,
            rmerge: 0,
            rmeas: 0,
            rpim: 0,
            score: 0,
            scoreVersion: "",
            uniqueReflections: 0
        },
        overallDataStatistics: {
            completeness: 0,
            halfSetCorrelation: 0,
            i: 0,
            redundancy: 0,
            reflections: 0,
            resolutionLower: 0,
            resolutionUpper: 0,
            rmerge: 0,
            rmeas: 0,
            rpim: 0,
            score: 0,
            scoreVersion: "",
            uniqueReflections: 0
        },
        parameters: "",
        promotedOn: "",
        completedOn: "",
        resolutionLower: 0,
        resolutionUpper: 0,
        rmerge: 0,
        sample: {
            clientCode: "",
            collection: {
                beamlineCode: "",
                companyClientCode: "",
                date: "",
                synchrotronCode: "",
                synchrotronTitle: "",
                uuid: ""
            },
            companyCode: "",
            compound: "",
            datasetType: "",
            name: "",
            protein: "",
            uuid: ""
        },
        spaceGroup: "",
        status: "",
        unitCell: "",
        uuid: ""
    });

    const getJobDetailData = async () => {
        if (!isLoading) {
            return;
        }

        const axios = await axiosBuilder(getIdTokenClaims);
        XrayProcessingControllerApiFactory({}, BASE_PATH, axios)
                .getSingleJobDetailByUuidUsingGET(collectionUuid, jobUuid, sampleUuid)
                .then((response: AxiosResponse<XrayJobDetailDTO>) => {
                    setIsLoading(false);
                    setJobDetail(response.data);
                })
                .catch((error: any) => {
                    console.log("[ProcessingXRay/JobDetail] Error=" + JSON.stringify(error));
                    enqueueSnackbar('Failed fetching XRay job detail data. ' + error.toString(), { variant: 'error' });
                });
    }

    useEffect(() => {
        getJobDetailData();
    });

    if (isLoading) {
        return (
                <div>
                    <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
                        <Link color="inherit" href={"/"}>Home</Link>
                        <Link color="inherit" href={"/processing/x-ray"}>X-Ray Processing</Link>
                        <Link color="inherit" href={"/processing/x-ray/collection/" + collectionUuid}>Collection
                            Detail</Link>
                        <Link color="inherit"
                              href={"/processing/x-ray/collection/" + collectionUuid + "/sample/" + sampleUuid}>Sample
                            Detail</Link>
                        <Typography className={classes.breadcrumbs}>Job Detail</Typography>
                    </Breadcrumbs>
                    <Divider className={classes.divider}/>
                    <ContentTitle componentValue={"h2"} title={"Job Detail"}/>
                    <PageLoading/>
                </div>
        );
    } else {
        return (
                <div>
                    <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
                        <Link color="inherit" href={"/"}>Home</Link>
                        <Link color="inherit" href={"/processing/x-ray"}>X-Ray Processing</Link>
                        <Link color="inherit" href={"/processing/x-ray/collection/" + collectionUuid}>Collection
                            Detail</Link>
                        <Link color="inherit"
                              href={"/processing/x-ray/collection/" + collectionUuid + "/sample/" + sampleUuid}>Sample
                            Detail</Link>
                        <Typography className={classes.breadcrumbs}>Job Detail</Typography>
                    </Breadcrumbs>
                    <Divider className={classes.divider}/>
                    <ContentTitle componentValue={"h2"} title={"Sample Summary"}/>
                    <Paper className={classes.jobData}>
                        <Typography>Name: {jobDetail.sample.name}</Typography>
                        <Typography>Client: {jobDetail.sample.companyCode}-{jobDetail.sample.clientCode}</Typography>
                        <Typography>
                            Collection: {moment(jobDetail.sample.collection.date).format("YYYYMMDD")}-{jobDetail.sample.collection.beamlineCode.toUpperCase()}-{jobDetail.sample.companyCode.toUpperCase()}-{jobDetail.sample.clientCode.toUpperCase()}
                        </Typography>
                        <Typography>Protein: {jobDetail.sample.protein}</Typography>
                        <Typography>Compound: {jobDetail.sample.compound}</Typography>
                    </Paper>
                    <ContentTitle componentValue={"h2"} title={"Job Detail"}/>
                    <Paper className={classes.jobData}>
                        <Typography>Completed
                            On: {moment(jobDetail.completedOn).format("MMM Do, YYYY")} at {moment(jobDetail.completedOn).format("h:m A")}</Typography>
                        <Typography>Promotion Status: {jobDetail.promotedOn === "" ? "-" : "Promoted"}</Typography>
                        <Typography>Parameters: {jobDetail.parameters}</Typography>
                        <Typography>Space Group: {jobDetail.spaceGroup}</Typography>
                        <Typography>Unit Cell: {jobDetail.unitCell}</Typography>
                        <PromoteButton isPromoted={jobDetail.promotedOn !== ""}
                                       collectionUuid={jobDetail.sample.collection.uuid}
                                       sampleUuid={jobDetail.sample.uuid} jobUuid={jobDetail.uuid}/>
                    </Paper>
                    <ContentTitle componentValue={"h3"} title={"Data Statistics - Overall"}/>
                    <Paper className={classes.jobData}>
                        <Typography>Resolution: {jobDetail.overallDataStatistics.resolutionUpper} - {jobDetail.overallDataStatistics.resolutionLower}</Typography>
                        <Typography>Completeness: {jobDetail.overallDataStatistics.completeness}</Typography>
                        <Typography>Reflections: {jobDetail.overallDataStatistics.reflections}</Typography>
                        <Typography>Unique Reflections: {jobDetail.overallDataStatistics.uniqueReflections}</Typography>
                        <Typography>Redundancy: {jobDetail.overallDataStatistics.redundancy}</Typography>
                        <Typography>R-Merge: {jobDetail.overallDataStatistics.rmerge}</Typography>
                        <Typography>R-Meas: {jobDetail.overallDataStatistics.rmeas}</Typography>
                        <Typography>I: {jobDetail.overallDataStatistics.i}</Typography>
                        <Typography>Half-Set
                            Correlation: {jobDetail.overallDataStatistics.halfSetCorrelation}</Typography>
                        <Typography>Score: {jobDetail.overallDataStatistics.score}</Typography>
                        <Typography>Score Version: {jobDetail.overallDataStatistics.scoreVersion}</Typography>
                    </Paper>
                    <ContentTitle componentValue={"h3"} title={"Data Statistics - High Resolution Bin"}/>
                    <Paper className={classes.jobData}>
                        <Typography>Resolution: {jobDetail.highResolutionBinDataStatistics.resolutionUpper} - {jobDetail.highResolutionBinDataStatistics.resolutionLower}</Typography>
                        <Typography>Completeness: {jobDetail.highResolutionBinDataStatistics.completeness}</Typography>
                        <Typography>Reflections: {jobDetail.highResolutionBinDataStatistics.reflections}</Typography>
                        <Typography>Unique
                            Reflections: {jobDetail.highResolutionBinDataStatistics.uniqueReflections}</Typography>
                        <Typography>Redundancy: {jobDetail.highResolutionBinDataStatistics.redundancy}</Typography>
                        <Typography>R-Merge: {jobDetail.highResolutionBinDataStatistics.rmerge}</Typography>
                        <Typography>R-Meas: {jobDetail.highResolutionBinDataStatistics.rmeas}</Typography>
                        <Typography>I: {jobDetail.highResolutionBinDataStatistics.i}</Typography>
                        <Typography>Half-Set
                            Correlation: {jobDetail.highResolutionBinDataStatistics.halfSetCorrelation}</Typography>
                        <Typography>Score: {jobDetail.highResolutionBinDataStatistics.score}</Typography>
                        <Typography>Score Version: {jobDetail.highResolutionBinDataStatistics.scoreVersion}</Typography>
                    </Paper>
                    <ContentTitle componentValue={"h3"} title={"Data Statistics - Low Resolution Bin"}/>
                    <Paper className={classes.jobData}>
                        <Typography>Resolution: {jobDetail.lowResolutionBinDataStatistics.resolutionUpper} - {jobDetail.lowResolutionBinDataStatistics.resolutionLower}</Typography>
                        <Typography>Completeness: {jobDetail.lowResolutionBinDataStatistics.completeness}</Typography>
                        <Typography>Reflections: {jobDetail.lowResolutionBinDataStatistics.reflections}</Typography>
                        <Typography>Unique
                            Reflections: {jobDetail.lowResolutionBinDataStatistics.uniqueReflections}</Typography>
                        <Typography>Redundancy: {jobDetail.lowResolutionBinDataStatistics.redundancy}</Typography>
                        <Typography>R-Merge: {jobDetail.lowResolutionBinDataStatistics.rmerge}</Typography>
                        <Typography>R-Meas: {jobDetail.lowResolutionBinDataStatistics.rmeas}</Typography>
                        <Typography>I: {jobDetail.lowResolutionBinDataStatistics.i}</Typography>
                        <Typography>Half-Set
                            Correlation: {jobDetail.lowResolutionBinDataStatistics.halfSetCorrelation}</Typography>
                        <Typography>Score: {jobDetail.lowResolutionBinDataStatistics.score}</Typography>
                        <Typography>Score Version: {jobDetail.lowResolutionBinDataStatistics.scoreVersion}</Typography>
                    </Paper>
                </div>
        );
    }
};

export default JobDetail;