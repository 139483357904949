import React, { useEffect, useState } from 'react';
import { useSnackbar } from "notistack";
import { useAuth0 } from "../../../components/auth/Auth0Provider";
import axiosBuilder from "../../../components/auth/AxiosBuilder";
import { XrayProcessingControllerApiFactory } from "../../../api";
import { BASE_PATH } from "../../../api/base";
import { Button, CircularProgress } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";

type XrayJobPromotionCoordinatesType = {
    isPromoted: boolean
    collectionUuid: string
    sampleUuid: string
    jobUuid: string
}

const PromoteButton = ({ isPromoted, collectionUuid, sampleUuid, jobUuid }: XrayJobPromotionCoordinatesType) => {

    const { enqueueSnackbar } = useSnackbar();
    const { getIdTokenClaims } = useAuth0();
    const [ promotionStatus, setPromotionStatus ] = useState<string>("Promoting");

    useEffect(() => {
        if (isPromoted) {
            setPromotionStatus("Promoted");
        } else {
            setPromotionStatus("Promote");
        }
    }, [isPromoted]);

    const promoteJob = async () => {
        if (promotionStatus !== "Promote") {
            return;
        }

        setPromotionStatus("Promoting");
        const axios = await axiosBuilder(getIdTokenClaims);
        XrayProcessingControllerApiFactory({}, BASE_PATH, axios)
                .promoteSingleJobByUuidUsingPOST(collectionUuid, jobUuid, sampleUuid)
                .then((response) => {
                    const actionResult = response.data;
                    if (actionResult.success) {
                        setPromotionStatus("Promoted")
                        enqueueSnackbar("Xray job successfully promoted.");
                    } else {
                        setPromotionStatus("Promote")
                        enqueueSnackbar("Xray job promotion failed: " + actionResult.errorMessage, { variant: "error" })
                    }
                })
                .catch((error: any) => {
                    setPromotionStatus("Promote")
                    console.log("[ProcessingXRay/PromoteJob] Error=" + JSON.stringify(error));
                    enqueueSnackbar("Failed promoting xray job. " + error.toString(), { variant: "error" });
                });
    }

    if (promotionStatus === "Promoted") {
        return (
                <Button variant="contained" color="secondary" disabled><GetAppIcon/> Unpromote</Button>
        );
    } else if (promotionStatus === "Promoting") {
        return (
                <Button variant="contained" color="primary" disabled><CircularProgress/> Promoting</Button>
        );
    } else {
        return (
                <Button variant="contained" color="primary" onClick={() => {
                    promoteJob()
                }}><PublishIcon/> Promote</Button>
        );
    }
};

export default PromoteButton;