import React from 'react';
import { AppRouteGroup } from "../components/app/AppRoutes";
import CompanyConfiguration from "../routes/configuration/company/CompanyConfiguration";
import SynchrotronConfiguration from "../routes/configuration/synchrotron/SynchrotronConfiguration";
import BusinessIcon from "@material-ui/icons/Business";
import SynchrotronIcon from "@material-ui/icons/ThreeSixty";

export const menuConfigurationRouteData: AppRouteGroup = {
    title: "Configuration",
    children: [
        {
            title: "Company Configuration",
            path: "/configuration/company",
            node: <CompanyConfiguration/>,
            icon: <BusinessIcon/>
        },
        {
            title: "Synchrotron Configuration",
            path: "/configuration/synchrotron",
            node: <SynchrotronConfiguration/>,
            icon: <SynchrotronIcon/>
        }
    ]
}