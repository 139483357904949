import React from "react";
import { AppRouteGroup } from "../components/app/AppRoutes";
import Profile from "../routes/profile/Profile";

export const menuUserRouteData: AppRouteGroup = {
    title: "User",
    children: [
        {
            title: "Profile",
            path: "/profile",
            node: <Profile/>
        }
    ]
}