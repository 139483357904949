import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axiosBuilder from "../../../components/auth/AxiosBuilder";
// @ts-ignore
import { XrayCollectionDetailDTO, XrayProcessingControllerApiFactory } from "../../../api";
// @ts-ignore
import { BASE_PATH } from "../../../api/base";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useAuth0 } from "../../../components/auth/Auth0Provider";
import {
    Breadcrumbs,
    Button,
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography
} from "@material-ui/core";
import PageLoading from "../../../components/layout/PageLoading";
import ContentTitle from "../../../components/layout/ContentTitle";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    collectionData: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(1),
    },
    breadcrumbs: {
        fontSize: "small"
    },
    divider: {
        margin: theme.spacing(2, 0, 3)
    }
}));

const CollectionDetail = () => {
    const classes = useStyles();
    const { collectionUuid } = useParams();
    const [ collectionDetail, setCollectionDetail ] = useState<XrayCollectionDetailDTO | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { getIdTokenClaims } = useAuth0();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const getCollectionDetailData = async () => {
        if (!isLoading) {
            return;
        }

        const axios = await axiosBuilder(getIdTokenClaims);
        XrayProcessingControllerApiFactory({}, BASE_PATH, axios)
                .getSingleCollectionDetailByUuidUsingGET(collectionUuid)
                .then((response: AxiosResponse<XrayCollectionDetailDTO>) => {
                    setIsLoading(false);
                    setCollectionDetail(response.data);
                })
                .catch((error: any) => {
                    console.log("[ProcessingXRay/CollectionDetail] Error=" + JSON.stringify(error));
                    enqueueSnackbar('Failed fetching XRay collection detail data. ' + error.toString(), { variant: 'error' });
                });
    }

    useEffect(() => {
        getCollectionDetailData();
    });

    if (isLoading) {
        return (
                <div>
                    <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
                        <Link color="inherit" href={"/"}>Home</Link>
                        <Link color="inherit" href={"/processing/x-ray"}>X-Ray Processing</Link>
                        <Typography className={classes.breadcrumbs}>Collection Detail</Typography>
                    </Breadcrumbs>
                    <Divider className={classes.divider}/>
                    <ContentTitle componentValue={"h2"} title={"Collection Detail"}/>
                    <PageLoading/>
                </div>
        );
    } else {
        return (
                <div>
                    <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb">
                        <Link color="inherit" href={"/"}>Home</Link>
                        <Link color="inherit" href={"/processing/x-ray"}>X-Ray Processing</Link>
                        <Typography className={classes.breadcrumbs}>Collection Detail</Typography>
                    </Breadcrumbs>
                    <Divider className={classes.divider}/>
                    <ContentTitle componentValue={"h2"} title={"Collection Detail"}/>
                    <Paper className={classes.collectionData}>
                        <Typography>Date: {moment(collectionDetail?.date).format("MMMM Do, YYYY")}</Typography>
                        <Typography>Synchrotron: {collectionDetail?.synchrotronTitle} ({collectionDetail?.synchrotronCode})</Typography>
                        <Typography>Company Client: {collectionDetail?.companyClientCode}</Typography>
                        <Typography>Beamline: {collectionDetail?.beamlineCode}</Typography>
                    </Paper>
                    <ContentTitle componentValue={"h3"} title={"Samples"}/>
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Protein</TableCell>
                                    <TableCell>Compound</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {collectionDetail?.samples.map((row, index) => {
                                    return (
                                            <TableRow
                                                    key={row.uuid}
                                                    style={index % 2 ? { background: "#f6f6f6" } : { background: "white" }}>
                                                <TableCell>
                                                    <Button
                                                            variant="contained"
                                                            href={"/processing/x-ray/collection/" + collectionDetail?.uuid + "/sample/" + row.uuid}>
                                                        Details
                                                    </Button>
                                                </TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.protein}</TableCell>
                                                <TableCell>{row.compound}</TableCell>
                                            </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
        );
    }
};

export default CollectionDetail;